const theme = {
  colors: {
    primary: '#003e7e',
    primaryLigther: '#003e7e',
    primaryDarker: '#002041',
    headerBg: '#fff',
    heroGradientDark: 'rgba(0, 32, 65, 0.83)',
    heroGradientLight: 'rgba(0, 62, 126, 0.83)',
    infoBg: '#fff',
    formBg: '#e9e9e9',
    footerBg: '#002041',
    fontColorOne: 'rgb(63, 64, 64)',
    darkFont: 'rgb(46, 54, 61)',
    linksFontColor: '#246f87',
    copyrightFontColor: 'rgba(255, 255, 255, 0.549)',
  },
  fonts: {
    body: "'Open Sans', sans-serif",
  },
  fontSizes: {
    title: '38px',
    subtitle: '36px',
    headerCopy: '30px',
    regularLargeCopy: '20px',
    regularCopy: '18px',
    strongCopy: '15px',
    legal: '12px',
  },
  fontWeights: {
    thin: 100,
    normal: 400,
    heading: 600,
    bold: 700,
  },
  breakpoints: {
    micro: '350px',
    mini: '480px',
    extraSmall: '576px',
    small: '640px',
    medium: '768px',
    large: '991px',
    extraLarge: '1200px',
  },
  space: [0, 4, 8, 16, 32, 64],
};

export default theme;
